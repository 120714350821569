import { Container, Row, Col, Card, Button } from "react-bootstrap";


function JoinTheFamily() {
  document.title = "COMPACT Join The Family";

  return (
   
  <Container className="my-5">
    {/* Header Section */}
    <header className="text-center mb-5 fw-bold">
        <h1>Welcome to COMP
        <span className="act">ACT</span> Family Ministries</h1>
        <p>
          Transforming lives through compassionate care and opportunities rooted
          in biblical values.
        </p>
      </header>

    {/* Content Sections */}
    <Row className="g-4">
      <Col md={6} className="p-3">
        <Card className="h-100">
          <Card.Body className="bg-blue text-white p-4">
            <Card.Title className="h3 fw-bold">Employment Opportunity</Card.Title>
            <Card.Text>
              Join our exceptional team of child care professionals in a
              nationally accredited, licensed, and progressive child welfare
              ministry rooted in biblical values to restore families.
            </Card.Text>
          </Card.Body>
          <Card.Footer className="bg-white border-0">
            <Button
              className="btn-secondary w-100 bg-blue"
              href="https://compact.family/jobs"
            >
              View Job Openings
            </Button>
          </Card.Footer>
        </Card>
      </Col>
      <Col md={6} className="p-3">
        <Card className="h-100">
          <Card.Body className="bg-blue text-white p-4">
            <Card.Title className="h3 fw-bold">
              A Short Term Mission with Eternal Outcomes
            </Card.Title>
            <Card.Text>
              Imagine your team interacting with kids, engaging in projects, and
              leaving your mark on Hillcrest campus. Mission teams nationwide
              visit our 52-acre campus to demonstrate Jesus’ love to children and
              teens in our care.
            </Card.Text>
          </Card.Body>
          <Card.Footer className="bg-white border-0">
            <Button
              className="btn-secondary w-100 bg-blue"
              href="https://compact.family/teams"
            >
              Join a Mission Team
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>

    <Row className="g-4">
      <Col md={6} className="p-3">
        <Card className="h-100">
          <Card.Body className="bg-blue text-white p-4">
            <Card.Title className="h3 fw-bold">Special Projects</Card.Title>
            <Card.Text>
              Your financial contributions ensure that we can care for every
              child on our campus. Projects may include repairs, furniture
              needs, landscaping, and security enhancements. To adopt a special
              project as an individual, group, or church, visit our website.
            </Card.Text>
          </Card.Body>
          <Card.Footer className="bg-white border-0">
            <Button
              className="btn-secondary w-100 bg-blue"
              href="https://compact.family/special-projects"
            >
              Adopt a Special Project
            </Button>
          </Card.Footer>
        </Card>
      </Col>
      <Col md={6} className="p-3">
        <Card className="h-100">
          <Card.Body className="bg-blue text-white p-4">
            <Card.Title className="h3 fw-bold">YES, We offer Internships</Card.Title>
            <Card.Text>
              Forge a path towards your future. Live on campus, share meals and
              experiences with the kids, contribute to projects, and collaborate
              with a dedicated team. Each summer, we offer internships for those
              who desire to make an eternal difference NOW and prepare for a
              life of compassionate action!
            </Card.Text>
          </Card.Body>
          <Card.Footer className="bg-white border-0">
            <Button
              className="btn-secondary w-100 bg-blue"
              href="https://compact.family/internships"
            >
              Learn About Internships
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>

    <Row className="g-4">
      <Col md={6} className="p-3">
        <Card className="h-100">
          <Card.Body className="bg-blue text-white p-4">
            <Card.Title className="h3 fw-bold">RV Volunteers</Card.Title>
            <Card.Text>
              We cherish our RV teams! Bring your RV and join us for a season.
              You’ll have opportunities to utilize your construction skills and
              bring joy to the faces of the children in our care. Our backyard
              boasts 26 miles of hiking trails in Hot Springs National Park and
              Ouachita Lake, a 48,000-acre recreational paradise.
            </Card.Text>
          </Card.Body>
          <Card.Footer className="bg-white border-0">
          <Button
  className="btn-secondary w-100 bg-blue"
  href="https://churchmobilization.ag.org/RV-Volunteers"
  target="_blank"
  rel="noopener noreferrer"
>
  Get Involved
</Button>

          </Card.Footer>
        </Card>
      </Col>
    </Row>
  </Container>
);

  
  
  
  
}

export default JoinTheFamily;
